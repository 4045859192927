.nav-container {
    /* position: fixed; */
    /* top: 0; */
    /* left: 0; */
    /* width: 97vw; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    /* width: 100vw; */
    /* padding-right: 20px; */
    background-color: white;
    /* background-color: blue; */

}

.center-btns {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.contact-btn {
    border-style: solid;
    border-width: 1px;
    border-radius: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    font-weight: 300;
}

a:link {
    font-size: 13.33px;
    font-weight: 400;
    text-decoration: none;
    justify-content: center;
    display: flex;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif
}

.resume-btn{
    color: rgb(0, 0, 0);
}

@media only screen and (max-width: 600px) {

    a:link {
        font-size: 13.33;
        font-weight: 400;
        text-decoration: none;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .resume-btn{
        color: rgb(0, 0, 0);
    }
    
    .nav-container {
        /* position: fixed; */
        /* top: 0; */
        /* left: 0; */
        /* width: 97vw; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* padding: 20px; */
        /* width: 100vw; */
        /* padding-right: 20px; */
        background-color: white;
        /* background-color: blue; */
    }
    
    .center-btns {
        display: flex;
        flex-direction: row;
    }
    
    .contact-btn {
        border-style: solid;
        border-width: 1px;
        border-radius: 7px;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
        font-weight: 300;
    }

}