.about-me-container {
    background-color: white;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    width: 100vw;

}
.about-content-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 50px;
    padding-left: 95px;
    padding-right: 95px;
    gap: 12px;
    max-width: 900px;
}

.tech-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    margin-top: 20px;
}