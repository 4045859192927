.hero-container {
    /* background-color: white; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-container {
    max-width: 900px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 200px;
    align-items: center;
    height: 600px;
    padding-left: 80px;
    padding-right: 80px;
    line-height: 1.2;
    background-color: white;
}

.my-face {
    height: 80%;
    /* width: auto; */
    object-fit: contain;
}

@media only screen and (max-width: 600px) {
    .hero-container {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        min-height: 80vh;

    }

    .content-container {
        text-align: center;
        max-width: 900px;
        min-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
        align-items: center;
        /* height: 600px; */
        padding-left: 80px;
        padding-right: 80px;
        line-height: 1.2;
        background-color: white;
    }
    
    .my-face {
        height: 70%;
        /* width: auto; */
        object-fit: contain;
    }
    
}

